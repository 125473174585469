const scrollToElement = require("scroll-to-element")

exports.onRouteUpdate = ({ location }) => scrollToAnchor(location, -82)

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [offset] - the height of any persistent nav -> document.querySelector(`nav`)
 */
function scrollToAnchor(location, offset = 0) {
  // Check for location so build does not fail
  if (location && location.hash) {
    const element = document.querySelector(`${location.hash}`)

    if (element) {
      scrollToElement(element, {
        offset,
        duration: 1,
        ease: "linear",
      })
    }
  }

  return true
}
